import React from "react";
import SessionRoutes from "./Views/Session/SessionRoutes";
import ApplicationRoutes from "./Views/Application/ApplicationRoutes";
import Redirection from "./Views/Application/Redirection";
import NotFound from "./Views/Application/NotFound";
import PermissionDenied from "./Views/Application/PermissionDenied";

const routes = [
  ...ApplicationRoutes,
  ...SessionRoutes,

  { path: "/", element: <><Redirection /></> },
  { path: "403", element: <><PermissionDenied /></> },
  { path: "*", element: <><NotFound /></> },
];

export default routes;
