import { Box, Typography, Link, Button, Container } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HomeIcon from '@mui/icons-material/Home';

const NotFound = () => {
    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: "flex", 
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                    textAlign: "center",
                    py: 8
                }}
            >
                <Box
                    component="img"
                    src={"/assets/images/kayana_logo.svg"}
                    alt="Kayana Icon"
                    sx={{
                        width: 120,
                        height: 120,
                        mb: 4,
                        opacity: 0.9
                    }}
                />
                
                <ErrorOutlineIcon 
                    sx={{ 
                        fontSize: 64,
                        color: "error.main",
                        mb: 2
                    }}
                />

                <Typography
                    variant="h2"
                    sx={{
                        fontSize: { xs: '3rem', md: '3.75rem' },
                        color: "text.primary",
                        fontWeight: 700,
                        mb: 1
                    }}
                >
                    404
                </Typography>

                <Typography
                    variant="h4"
                    sx={{
                        fontSize: { xs: '1.5rem', md: '2rem' },
                        color: "text.primary",
                        fontWeight: 600,
                        mb: 2,
                        letterSpacing: 0.5
                    }}
                >
                    Page Not Found
                </Typography>

                <Typography
                    variant="body1"
                    sx={{
                        color: "text.secondary",
                        mb: 4,
                        maxWidth: "sm",
                        lineHeight: 1.6
                    }}
                >
                    We apologize, but the page you are looking for doesn't exist or has been moved. 
                    Please check the URL or navigate back to our homepage.
                </Typography>

                <Button
                    component={Link}
                    href="/"
                    variant="contained"
                    size="large"
                    startIcon={<HomeIcon />}
                    sx={{
                        py: 1.5,
                        px: 4,
                        borderRadius: 2,
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 500,
                        boxShadow: 2,
                        '&:hover': {
                            boxShadow: 4
                        }
                    }}
                >
                    Return to Homepage
                </Button>
            </Box>
        </Container>
    );
};

export default NotFound;
